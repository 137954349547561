import React, { Component } from "react";
import { FaBriefcase, FaBuilding, FaCity } from "react-icons/fa";
import { IoMdPricetag } from "react-icons/io";

import { Url } from "./../component/url";
import "./../css/component/price.css";

const priceItemList = [
  {
    id: "free",
    header: {
      icon: "briefcase",
      name: "Mi primer negocio",
      description:
        "Para pequeños negocios en crecimiento que buscan darse a conocer y agilizar sus procesos.",
    },
    pricing: {
      price: "$149 / mes",
      title: "Precio de contratación fijo por sucursal.",
      subtitle: "Sin plazos forzosos y cancelación inmediata sin costo.",
      showTestButton: false,
      features: [
        "Generación de QR.",
        "Menú digital e interactivo.",
        "Pedidos vía WhatsApp.",
        "Comanda digital vía WhatsApp.",
        "Gestión del menú desde smartphone o computadora.",
        "Hasta 100 productos para registrar.",
        "Carga hasta 100 fotografías de alta resolución.",
        "Crea descuentos a tus productos.",
        "Soporte continuo 24/7.",
      ],
    },
  },
  {
    id: "plus",
    selected: true,
    header: {
      icon: "building",
      badge: "Lo más popular",
      name: "Negocio Plus",
      description:
        "La opción ideal para dar el siguiente paso, llevar el control total de tus ventas y clientes.",
    },
    pricing: {
      realPrice: "$400 / mes",
      price: "$199 / mes",
      title: "Precio de contratación fijo por sucursal.",
      subtitle: "Sin plazos forzosos y cancelación inmediata sin costo.",
      showTestButton: false,
      featuresTitle: '➕ Todo lo de "Mi primer negocio".',
      features: [
        "Punto de venta lite (POS).",
        "Pago de pedidos en línea.",
        "Administrador de clientes (CRM).",
        "Envío de promociones a clientes por WhatsApp.",
        "Dashboard de clientes.",
        "Dashboard de promociones.",
        "Dashboard estándar de ventas.",
        "10 asesorías personalizadas de negocio.",
        "Sin límite de productos para registrar.",
        "Sin límite para cargar fotografías de alta resolución.",
      ],
    },
  },
  {
    id: "advanced",
    header: {
      icon: "city",
      name: "Negocio Profesional",
      description:
        "Enfocado en maximizar la rentabilidad usando la experiencia de clientes y sacando provecho a los datos.",
    },
    pricing: {
      realPrice: "$800 / mes",
      price: "$399 / mes",
      title: "Precio de contratación fijo por sucursal.",
      subtitle: "Sin plazos forzosos y cancelación inmediata sin costo.",
      showTestButton: true,
      featuresTitle: '➕ Todo lo de "Negocio Plus".',
      features: [
        "Inteligencia artificial en menú.",
        "Personalización del menú.",
        "Modulo de gestión de ingresos y costos.",
        "Dashboard avanzado de clientes.",
        "Dashboard avanzado de promociones.",
        "Dashboard avanzado con análisis de ventas.",
        "Dashboard avanzado con proyecciones de ventas.",
        "Dashboard avanzado con análisis de productos.",
        "Asesorías ilimitadas.",
        "Asesoria para duplicar tus ganancias (utilidad).",
        "Asesoria para fidelizar clientes de Uber Eats, Rappi, Didi Food, etc...",
        "Asesoria para salir de aplicaciones de delivery aumentando ganancias.",
        "Mensajes masivos por WhatsApp o correo.",
      ],
    },
  },
];

const iconMap = {
  briefcase: <FaBriefcase size={28} />,
  building: <FaBuilding size={28} />,
  city: <FaCity size={28} />,
};

const startButtonClass = (selectedByDefault = false) => {
  return selectedByDefault
    ? "base-btn inverse-btn-start"
    : "base-btn btn-start";
};

class Price1 extends Component {
  render() {
    return (
      <div className="row">
        {priceItemList.map(({ id, selected, header, pricing }) => {
          const itemBadge = header.badge ? (
            <div className="item-badge principal-color">{header.badge}</div>
          ) : (
            ""
          );
          const icon = iconMap[header.icon];
          const featuresTitle = pricing.featuresTitle ? (
            <p className="features-title">{pricing.featuresTitle}</p>
          ) : (
            ""
          );

          return (
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <section className="card-item">
                {itemBadge}
                <div className="item-header container-p">
                  <div className="item-icon text-left mb-4">{icon}</div>
                  <h4 className="item-name text-left">{header.name}</h4>
                  <p className="item-description text-left">
                    {header.description}
                  </p>
                </div>

                <section className="item-pricing bg-gray container-p text-left">
                  <p className="p-0 m-0 regular-price">
                    {pricing.realPrice ? (
                      <>
                        Precio normal{" "}
                        <span className="cross-text">{pricing.realPrice}</span>
                      </>
                    ) : null}
                  </p>
                  <h2 className="promo-price d-flex align-items-center justify-content-between">
                    <span className="mr-2">{pricing.price}</span>{" "}
                    {id !== "free" ? (
                      <span className="promo-label">
                        <IoMdPricetag size={25} />
                        Promoción
                      </span>
                    ) : null}
                  </h2>
                  <p className="p-0 m-0">{pricing.title}</p>
                  <p className="text-black-50 p-0 m-0">{pricing.subtitle}</p>
                  <div className="w-100 text-center mt-4">
                    <a
                      href={Url.signupUrl + "?id=" + id}
                      className={startButtonClass(selected)}
                    >
                      {id === "free" ? "Empezar GRATIS" : "Contratar"}
                    </a>
                  </div>
                </section>

                <div className="item-features bg-gray container-p text-left pt-4">
                  {featuresTitle}
                  <ul className="features-list">
                    {pricing.features.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>
                </div>
              </section>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Price1;
