import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { RedirectId, RedirectKey } from './component/redirect';
import ScrollToTop from './component/scrollTotop';
import SearchBody from './component/search-body';
import App from './home/App';
import * as serviceWorker from './serviceWorker';
import { DeviceProvider } from './context/DeviceContext';


class Root extends Component{
  render(){
    return(
      <BrowserRouter>
        <SearchBody />
        <ScrollToTop />
        <Switch>
          <DeviceProvider>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={App}/>
            <Route exact path={`${process.env.PUBLIC_URL}/customer/menu/key/:key`} component={RedirectKey}></Route>
            <Route exact path={`${process.env.PUBLIC_URL}/customer/menu/:id`} component={RedirectId}></Route>
          </DeviceProvider>
        </Switch>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
