import React, { Component } from "react";
import { FaUsers, FaUserTie } from "react-icons/fa";
import { AiFillHeart } from "react-icons/ai";
import SectionHeader from "./../component/section-header";
import "./../css/feature.css";

const FeatureList = [
  {
    icon: <AiFillHeart />,
    title: "Crecimiento Sostenible",
    description:
      "Nos dedicamos a impulsar el crecimiento a largo plazo de tu restaurante, habilitando herramientas para el aumento de tus ventas de manera sostenible.",
  },
  {
    icon: <FaUsers />,
    title: "Potenciar la Cultura Gastronómica",
    description:
      "Nos apasiona difundir el encanto de la cocina mexicana, asegurando que cada mexicano tenga acceso rápido y sencillo a una amplia variedad de opciones culinarias auténticas.",
  },
  {
    icon: <FaUserTie />,
    title: "Experiencia del Cliente Mejorada",
    description:
      "Utilizamos datos para enriquecer la experiencia del cliente, ofreciendo recomendaciones personalizadas que fomentan la lealtad y el crecimiento del negocio.",
  },
];

// header section content
const title = <h3 className="mb--25">Misiones</h3>;
const subtitle = (
  <h6>NotMenu ha apoyado a miles de negocios de una forma fácil y rápida.</h6>
);
const alignment = "section-header text-center pb--60";

class Feature extends Component {
  render() {
    return (
      <section
        id="feature"
        className="feature-section pt--60 pb--60 pt_lg--100 pb_lg--100"
      >
        <div className="container">
          <SectionHeader
            title={title}
            subtitle={subtitle}
            alignment={alignment}
          />
        </div>
        <div className="container">
          <div className="row justify-content-center justify-content-lg-start">
            {FeatureList.map((val, i) => (
              <div className="col-md-6 col-lg-4 mb--30 mb-lg-0" key={i}>
                <div className="feature-item text-left">
                  <div className="icon-box">
                    <div className="icon">{val.icon}</div>
                    <img
                      src="assets/images/feature/feature-dot-shape.png"
                      alt="dot shape"
                    />
                  </div>
                  <h5>{val.title}</h5>
                  <p>{val.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Feature;
