import React, { Component } from 'react';
import ModalVideo from 'react-modal-video'
import { FaPlay } from "react-icons/fa";
import './../css/video-section.css';


class VideoSection extends Component {
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    
    openModal () {
        this.setState({isOpen: true})
    }
    render() {
        return (
            <section id="video-notmenu" className="video-notmenu-section pt--50 pb--50">
                <div className="video-section text-center">
                    <div className="overlay pt--60 pb--60 pt_lg--160 pb_lg--160">
                        <div className="axsis-video-btn">
                            <span className="before-btn">Mira este video</span>
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='TZIjRte5lNY' onClose={() => this.setState({isOpen: false})} />
                            <button className="axsis-play-btn" onClick={this.openModal}><FaPlay /></button>
                            <span className="after-btn">para conocer más</span>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default VideoSection;