import React, { Component } from "react";
import "./../css/component/contact.css";

class ContactForm3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      axName: "",
      axEmail: "",
      axSubject: "",
      axMassage: "",
      classAlert: "contact-alert-hidden",
      result: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();

    const data = {
      name: this.state.axName,
      email: this.state.axEmail,
      subject: this.state.axSubject,
      description: this.state.axMassage,
    };

    let url = `https://api.whatsapp.com/send?phone=5524998882&text=Hola NotMenu,
    Nombre: ${data.name}
    Email: ${data.email}
    Asunto: ${data.subject}
    Mensaje:
    ${data.description}`;

    url = encodeURI(url);
    window.open(url, "_blank");

    /*
    const response = await fetch(url, {
      method: "POST",
    });

    if (response.status !== 201) {
      this.setState({ result: "Ocurrio un error, intenta nuevamente." });
      this.setState({ classAlert: "contact-alert contact-alert-error" });
    } else {
      this.setState({ result: "El mensaje se envió con éxito." });
      this.setState({ classAlert: "contact-alert contact-alert-success" });
    }*/
  }

  render() {
    return (
      <form className="contact-form" onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-lg-6 mb--30 mb-lg-0">
            <input
              type="text"
              name="name"
              id="item01"
              value={this.state.axName}
              onChange={(e) => {
                this.setState({ axName: e.target.value });
              }}
              placeholder="Escribe tu nombre *"
            />
            <input
              type="text"
              name="email"
              id="item02"
              value={this.state.axEmail}
              onChange={(e) => {
                this.setState({ axEmail: e.target.value });
              }}
              placeholder="Escribe tu email *"
            />
            <input
              type="text"
              name="subject"
              id="item03"
              value={this.state.axSubject}
              onChange={(e) => {
                this.setState({ axSubject: e.target.value });
              }}
              placeholder="Escribe el asunto"
            />
          </div>
          <div className="col-lg-6">
            <textarea
              type="text"
              id="item04"
              name="message"
              value={this.state.axMassage}
              onChange={(e) => {
                this.setState({ axMassage: e.target.value });
              }}
              placeholder="Tu mensaje"
            />
          </div>
          <div className="col-12 text-center pt--50">
            <button
              className="submit-btn"
              type="submit"
              value="submit"
              name="submit"
              id="mc-embedded-subscribe"
            >
              Enviar al WhatsApp
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center pt--20">
            <div className={this.state.classAlert}>{this.state.result}</div>
          </div>
        </div>
      </form>
    );
  }
}

export default ContactForm3;
