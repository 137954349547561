import React, { Component } from "react";
import SectionHeader from "./../component/section-header";
import { AiOutlineThunderbolt, AiOutlineSync } from "react-icons/ai";
import { FaHandHoldingHeart, FaHome } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { RiUserHeartLine } from "react-icons/ri";
import "./../css/screenshot.css";
import { Url } from "./../component/url";
import { DeviceContext } from '../context/DeviceContext';

// section header content const
const title = (
  <h3 className="mb--15">
    Enamora a tus clientes,
    <br />
    con una poderosa herramienta
  </h3>
);
const subtitle = (
  <h6 className="sub-title">
    Con el diseño intuitivo de NotMenu tus clientes se sentirán en confianza de
    pedir más.
  </h6>
);
const alignment = "section-header text-lg-left pb--40";

// section header 2 content const
const title2 = (
  <h3 className="mb--15">
    Innova tu negocio,
    <br /> con la tecnología de NotMenu
  </h3>
);
const subtitle2 = (
  <h6 className="sub-title">
    Forma parte de la comunidad de personas que están innovando en su negocio.
  </h6>
);
const alignment2 = "section-header text-lg-left pb--20";

// section header 3 content const
const title3 = (
  <h3 className="mb-3">
    Powerful software,
    <br /> beautifully designed
  </h3>
);
const subtitle3 = (
  <h6 className="sub-title mb--30">
    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
    ligula eget dolor.{" "}
  </h6>
);
const alignment3 = "section-header text-lg-left";

const ssInfoList1 = [
  {
    icon: <AiOutlineThunderbolt />,
    title: "Fácil de usar",
    description: "Crea tu menú en minutos sin necesidad de ser un experto.",
  },
  {
    icon: <AiOutlineSync />,
    title: "Aumenta el ticket de venta",
    description:
      "Está comprobado que los clientes que ven las fotos de los productos tienden a pedir un 40% más.",
  },
  {
    icon: <FaHandHoldingHeart />,
    title: "Sin comisiones",
    description:
      "En NotMenu apoyamos a los negocios sin cobrar por cada venta.",
  },
];

const ssInfoList2 = [
  {
    icon: <FiCheckCircle />,
    description:
      "Tus clientes no tienen que descargar nada, solo necesitan leer el QR o entrar a la pagina web que te creamos.",
  },
  {
    icon: <FiCheckCircle />,
    description:
      "Contamos con 2 tipos de pedidos: Para recoger o envío a domicilio, tú eres libre de activar/desactivar cualquier tipo de pedido en cualquier momento.",
  },
  {
    icon: <FiCheckCircle />,
    description:
      "Crea promociones especiales sobre productos que quieres impulsar.",
  },
];

class ScreenShot extends Component {
  render() {
    return (
      <section
        id="screenshot"
        className="screenshot-section bg-ash-color pt--60 pb--60 pt_lg--70 pb_lg--70"
      >
        <div className="container position-relative pt--30 pb--30 pt_lg--50 pb_lg--50">
          <div className="row">
            <div className="col-lg-5 order-lg-last">
              <div className="screenshot-content text-center text-lg-left">
                <SectionHeader
                  title={title}
                  subtitle={subtitle}
                  alignment={alignment}
                />

                <div className="screenshot-info-container-style1 hover-effect-parent1 text-left">
                  {ssInfoList1.map((val, i) => (
                    <div
                      className="screenshot-info-item d-flex flex-wrap"
                      key={i}
                    >
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h6 className="font-weight-bold mb-2 mt-0">
                          {val.title}
                        </h6>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-lg-first pr_lg--100">
              <div className="screenshot-image image1">
                <img
                  src="assets/images/screenshot/screenshot-img1.png"
                  alt="screenshot"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container position-relative pt--30 pb--30 pt_lg--50 pb_lg--50">
          <div className="row">
            <div className="col-lg-5">
              <div className="screenshot-content text-center text-lg-left">
                <SectionHeader
                  title={title2}
                  subtitle={subtitle2}
                  alignment={alignment2}
                />
                <div className="screenshot-info-container-style2 text-left">
                  {ssInfoList2.map((val, i) => (
                    <div
                      className="screenshot-info-item-style2 d-flex flex-wrap"
                      key={i}
                    >
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <p>{val.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <br />
                <div className="custom-btn-group">
                  <a
                    href="#pricing"
                    className="custom-btn start-test mb-2 mb-lg-0 mx-2 ml-lg-0"
                  >
                    <RiUserHeartLine /> Comienza ahora
                  </a>

                  <DeviceContext.Consumer>
                    {(isMobile) => {
                      return isMobile ? 
                      <a
                        href={Url.demoUrl}
                        className="custom-btn mx-2"
                        target="_blank"
                      >
                        <FaHome /> Ver demo
                      </a> : null
                    }}
                  </DeviceContext.Consumer>
                </div>
              </div>
            </div>

            <div className="col-lg-7 pl_lg--100">
              <div className="screenshot-image image2">
                <img
                  src="assets/images/screenshot/screenshot-img2.png"
                  alt="images"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ScreenShot;
