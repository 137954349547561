import React, { createContext, useState } from 'react';

const DeviceContext = createContext();

const DeviceProvider = ({ children }) => {
  const [isMobile] = useState(
    /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent) ||
      'ontouchstart' in document.documentElement
  );

  return (
    <DeviceContext.Provider value={isMobile}>{children}</DeviceContext.Provider>
  );
};

export { DeviceProvider, DeviceContext };
