import './../css/component/call-to-action.css';

import React, { useContext } from 'react';
import { FaHome } from 'react-icons/fa';
import { RiUserHeartLine } from 'react-icons/ri';

import { Url } from './../component/url';
import { DeviceContext } from '../context/DeviceContext';

function CallToAction() {
        const isMobile = useContext(DeviceContext);
        return (
            <div className="call-to-action">
                <a href="#pricing" className="custom-btn custom-btn-main d-inline-flex align-items-center call-to-action-principal-button"><RiUserHeartLine /><p>Comienza<span>Ahora</span></p></a>
                
                {isMobile ? (
                    <a href={Url.demoUrl} target="_blank" className="custom-btn d-inline-flex align-items-center call-to-action-principal-button"><FaHome /><p>Ver<span>Demo</span></p></a>    
                ) : null}
            </div>
        )
}

export default CallToAction;