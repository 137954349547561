import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SectionHeader from "./../component/section-header";
import { FiCheckCircle } from "react-icons/fi";
import Price1 from "./../component/price1";
import "./../css/pricing-section.css";

const ssGeneralPlan = [
  {
    icon: <FiCheckCircle />,
    description: "Menú ó Catálogo siempre disponible en línea.",
  },
  {
    icon: <FiCheckCircle />,
    description: "QR personalizado para ver el menú o catálogo.",
  },
  {
    icon: <FiCheckCircle />,
    description: "Pedidos a domicilio y para recoger usando WhatsApp",
  },
  {
    icon: <FiCheckCircle />,
    description:
      "Panel de administrador para modificar tu menú o catálogo en cualquier momento.",
  },
  {
    icon: <FiCheckCircle />,
    description:
      "Aplicación para leer los QR de los pedidos en sitio, solo disponible para Android. ",
  },
];

// header section content
const title = (
  <h3 className="mb--15">
    Empieza a ver cómo tus ventas crecen al probar cualquiera de nuestros planes
    hoy mismo
  </h3>
);
const subtitle = ""; /* <div className="section-plan">
    <div className="subtitle-prices">
        <h6>Todos nuestros planes incluyen estos beneficios</h6>
    </div>
    <br />
<div className="screenshot-info-container-style2 text-left">

        {ssGeneralPlan.map( (val , i) => (
            <div className="screenshot-info-item-style2 d-flex flex-wrap" key={i}>
                <div className="icon">
                    {val.icon}
                </div>
                <div className="content">
                    <p>{val.description}</p>
                </div>
            </div>
        ))}
    </div>
</div>;*/
const alignment = "section-header text-center pb--60";

class PricingSection extends Component {
  render() {
    let tab1 = "Mensual";
    return (
      <section id="pricing" className="pricing-section pt--50 pb--50">
        <div className="container p-lg-0">
          <SectionHeader
            title={title}
            subtitle={subtitle}
            alignment={alignment}
          />
        </div>
        <div className="container p-lg-0">
          <div className="pricing-tab-content-body">
            <Price1></Price1>
          </div>
        </div>
      </section>
    );
  }
}

export default PricingSection;
